import { useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import React from "react";
import { Link } from "react-router-dom";
import "./Header.scss";

export default function Header() {
  const wallet = useWallet();
  const disconnectWallet = async () => {
    if (wallet.connected) {
      wallet.disconnect();
    }
  };
  return (
    <header>
      <div onClick={disconnectWallet} className="header-logo">
        <Link to="/">
          <img src="/assets/exit.png" alt="exit" />
        </Link>
      </div>
      <WalletMultiButton />
    </header>
  );
}
