import React, { useEffect, useRef } from 'react';
import "./Popup.scss";

const Popup = ({ responseMessage, setPopup }) => {

    const popupContainer = useRef(null)

    useEffect(() => {
        document.addEventListener("mousedown", onMouseDown);
        return () => {
            document.removeEventListener("mousedown", onMouseDown);
        };
    });

    const onMouseDown = e => {
        if (
            popupContainer.current === e.target ||
            popupContainer.current.contains(e.target)
        ) {
            return
        } else {
            setPopup(false)
        }
    };

    return (
        <div className="blocker">
            <div ref={popupContainer} className="popup">
                <div className="popupBody">{responseMessage}</div>
                <input onClick={() => setPopup(false)} className="popupButton" type="button" value="OK" />
            </div>
        </div>

    )
}

export default Popup