import React, { useEffect } from "react"
import { useWallet } from "@solana/wallet-adapter-react";
import { useNavigate } from "react-router-dom";

const Failed = () => {
  const wallet = useWallet();
  const navigate = useNavigate();

  useEffect(() => {
    if (wallet.connected === false) {
      navigate("/");
    }
  }, [wallet.connected]);

  return(
  <div className="mainWrapper">
    <div className="sectionWrapper">
      <h1>Ahh sorry! </h1>
      <p>Your wallet is not on the gift list! Try with another wallet. </p>
      <div className="sectionButton">
        <input className="mainButton" type="button" value="Connect wallet"/>
      </div>
    </div>
  </div>
  );
};
export default Failed;
