import React, { useEffect } from "react"
import { useWallet } from "@solana/wallet-adapter-react";
import { useNavigate } from "react-router-dom";

const Congrats = () => {

  const wallet = useWallet();
  const navigate = useNavigate();

  useEffect(() => {
    if (wallet.connected === false) {
      navigate("/");
    }
  }, [wallet.connected]);

  return (
    <div className="mainWrapper">
    <div className="sectionWrapper">
      <h1>Thank you for submitting!</h1>
      <p>The email with your present will be delivered soon. </p>
    </div>
  </div>
  );
};
export default Congrats;
