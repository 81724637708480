import { useWallet } from "@solana/wallet-adapter-react";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useWalletModal } from "@solana/wallet-adapter-react-ui";

const Home = () => {
  const { connected } = useWallet();
  const navigate = useNavigate();
  const { setVisible } = useWalletModal();

  useMemo(() => {
    if (connected) {
      navigate("/sign-form");
    }
  }, [connected]);

  return (
    <div className="mainWrapper">
      <div className="sectionWrapper">
        <h1>Redeem your EXIT gift NOW!</h1>
        <p>Please connect your wallet to redeem EXIT NFT gift. </p>
        <div className="sectionButton">
          <input
            onClick={setVisible}
            className="mainButton"
            type="button"
            value="Connect wallet"
          />
        </div>
      </div>
    </div>
  );
};
export default Home;
