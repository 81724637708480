import React, { useState, useEffect } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import "./FormPage.scss";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useWallet } from "@solana/wallet-adapter-react";
import {
  SystemProgram,
  Transaction,
  TransactionInstruction,
} from "@solana/web3.js";
import { connect } from "all-art-core/lib/core/connection";
import { Buffer } from "buffer/index";
import Spinner from "../../Components/Spinner/Spinner";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Popup from "../../Components/Popup/Popup";
import validator from "validator";
import { API } from "../../config";

const FormPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [popup, setPopup] = useState(false)
  const [responseMessage, setResponseMessage] = useState('')
  const {
    register,
    handleSubmit,
    watch, setError,
    formState: { errors },
    control,
    ...restForm
  } = useForm({});

  const item = watch();

  const { firstName, lastName, email, phoneNumber, terms } = item;

  const wallet = useWallet();
  const navigate = useNavigate();

  const onSubmit = async (data) => {

    if (!validator.isEmail(data.email)) {
      setError('email', { type: 'custom', message: 'E-mail is not valid' })
    }
    if (!validator.isMobilePhone("+" + data.phoneNumber, "any", {
      strictMode: true
    })) {
      setError('phoneNumber', { type: 'custom', message: 'Phone number is not valid' })
    }
    if (!validator.isEmail(data.email) || !validator.isMobilePhone("+" + data.phoneNumber, "any", {
      strickMode: true
    })) {
      return
    }

    if (terms && firstName && lastName && email && phoneNumber) {
      setIsLoading(true);
      try {
        const newData = {
          firstName: data.firstName,
          lastName: data.lastName,
          phone: data.phoneNumber,
          email: data.email,
          wallet: wallet.publicKey.toBase58(),
          transaction: await handleSignMessage()
        }
        console.log('NEW DATA', JSON.stringify(newData));
        await axios.post(`${API}/redeem`, newData)
        navigate("/congrats");
        setIsLoading(false)
      } catch (error) {
        console.log("ERROR", error);
        setIsLoading(false)
        setResponseMessage(error.response.data.err.message)
        setPopup(true)
      }
    }
  };

  const handleSignMessage = async () => {
    const connection = await connect(
      "https://frosty-red-sound.solana-devnet.quiknode.pro/7c93288609cd4e6234137238c03cc8158e288e8b/"
    );
    const tx = new Transaction().add(
      new TransactionInstruction({
        keys: [
          {
            isSigner: true,
            isWritable: true,
            pubkey: wallet.publicKey,
          },
          {
            isSigner: false,
            isWritable: true,
            pubkey: wallet.publicKey,
          },
        ],
        data: Buffer.from([
          {
            fromPubkey: wallet.publicKey,
            lamports: 0,
            toPubkey: wallet.publicKey,
          },
        ]),
        programId: SystemProgram.programId,
      })
    );
    tx.feePayer = wallet.publicKey;
    tx.recentBlockhash = (
      await connection.getLatestBlockhash("confirmed")
    ).blockhash;
    const signedArWeaveTransaction = await wallet.signTransaction(tx);

    const base64Transaction = signedArWeaveTransaction
      .serialize()
      .toString("base64");

    return base64Transaction;
  };

  useEffect(() => {
    if (wallet.connected === false) {
      navigate("/");
    }
  }, [wallet.connected]);

  console.log(errors);

  return (
    <div className={`formPage`}>
      <div className="formHeader">
        <h1>Contact details</h1>
        <p>Please enter your contact details below so we can send you a gift.</p>
      </div>
      <FormProvider {...{ register, handleSubmit, control, ...restForm }}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={`formContainer`}
        >
          <div className="inputFields">
            <div className={`inputSection ${errors.firstName && "errorInput"}`}>
              <Controller
                control={control}
                name="firstName"
                rules={{
                  required: true,
                  minLength: 3,
                }}
                render={({ field: { onChange, value } }) => (
                  <input
                    placeholder="First Name"
                    type="text"
                    value={value}
                    onChange={(e) => {
                      const newValue = e.target.value.replace(/[^\w\s]/gi, "");
                      onChange(newValue);
                    }}
                  />
                )}
              />
              <div className="messageContainer">
                {firstName && !errors.firstName && <span>First Name</span>}
                {errors.firstName && errors.firstName.type === "required" && (
                  <span className="errorMessage">First Name is required !</span>
                )}
                {errors.firstName && errors.firstName.type === "minLength" && (
                  <span className="errorMessage">
                    First Name must have at least 3 characters
                  </span>
                )}

              </div>
            </div>

            <div className={`inputSection ${errors.lastName && "errorInput"}`}>
              <Controller
                control={control}
                name="lastName"
                rules={{
                  required: true,
                  minLength: 3,
                }}
                render={({ field: { onChange, value } }) => (
                  <input
                    placeholder="Last Name"
                    type="text"
                    value={value}
                    className={errors.lastName ? "errorInput" : ""}
                    onChange={(e) => {
                      const newValue = e.target.value.replace(/[^\w\s]/gi, "");
                      onChange(newValue);
                    }}
                  />
                )}
              />
              <div className="messageContainer">
                {lastName && !errors.lastName && <span>Last Name</span>}
                {errors.lastName && errors.lastName.type === "required" && (
                  <span className="errorMessage">Last Name is required !</span>
                )}
                {errors.lastName && errors.lastName.type === "minLength" && (
                  <span className="errorMessage">
                    Last Name must have at least 3 characters
                  </span>
                )}

              </div>
            </div>

            <div className={`inputSection ${errors.email && "errorInput"}`}>
              <input
                {...register("email", {
                  required: true
                })}
                placeholder="Email"
                type='text'
                className={errors.email ? "errorInput" : ""}
              />
              <div className="messageContainer">
                {email && !errors.email && <span>Email</span>}
                {errors.email && errors.email.type === "required" && (
                  <span className="errorMessage">E-mail is required !</span>
                )}
                {errors.email && (errors.email.type === 'custom') && (
                  <span className="errorMessage">{errors.email.message}</span>
                )}
              </div>
            </div>

            <div className={`inputSection ${errors.phoneNumber && "errorInput"}`}>
              <Controller
                name="phoneNumber"
                control={control}
                rules={{
                  required: true
                }}
                render={({ field: { onChange, value } }) => (
                  <PhoneInput
                    // enableAreaCodes
                    country={'rs'}
                    onChange={onChange}
                    value={value}
                    enableSearch
                    placeholder="Mobile phone number"
                    dropdownClass="dropdown"
                    searchClass="search"
                    inputClass={`phoneNumberInput ${errors.phoneNumber && "errorInput"} border-0 bg-disabled`}
                    buttonClass="no-border border-0"
                  />
                )}
              />
              <div className="messageContainer">
                {phoneNumber && !errors.phoneNumber && <span>Mobile Phone Number</span>}
                {errors.phoneNumber && errors.phoneNumber.type === "required" && (
                  <span className="errorMessage">Phone Number is required !</span>
                )}
                {errors.phoneNumber && errors.phoneNumber.type === 'custom' && (
                  <span className="errorMessage">{errors.phoneNumber.message}</span>
                )}
              </div>
            </div>
          </div>

          <div className="termsContainer">
            <div className="terms">
              <input
                {...register("terms", { required: true })}
                type="checkbox"
                id="terms"
              />
              <label htmlFor="terms">
                By submitting, you accept the terms and conditions of the <a href="https://www.exitfest.org/en/terms-of-use" target="_blank">Terms of Use</a> and our <a href="https://www.exitfest.org/en/privacy-policy" target="_blank">Policy Privacy</a>.
                <br /> © 2022 EXIT Team. All rights reserved.
              </label>
            </div>
            <div className="errorMessage">
              {errors.terms && errors.terms.type === "required" ? "Terms are not accepted !" : ""}
            </div>
          </div>

          <div>
            <input className={`submitButton`} type="submit" />
          </div>

        </form>
      </FormProvider>
      {/* <Popup/> */}
      {isLoading && <Spinner />}
      {popup && <Popup setPopup={setPopup} responseMessage={responseMessage} />}
    </div>
  );
};

export default FormPage;
